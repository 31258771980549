import * as $ from 'jquery';
import * as React from 'react';
import Component from '../../../Component';
import { filtersFormUrlFactory, leavesFactory } from '../../../Factories';
import { LEAVESTATUS, ORDER, STATUS } from '../../../General';
import Table from './Table';

export default class Manager extends Component {
    constructor(props?: any) {
        super(props);
        this.state = {
            filters: this.createFilters(),
            leaves: { arr: [], status: STATUS.LOADING }
        };
    }

    createFilters() {
        const moment = this.getMomentWithLocale();
        let filters = {
            orderBy: { field: 'DATE', order: ORDER.DESC },
            users: {
                value: [this.getEmployee()],
            },
            leaveStatuses: {
                value: [LEAVESTATUS.AWAITING_APPROVAL, LEAVESTATUS.APPROVED]
            },
            leaveTypes: {
                value: [],
            },
            startDate: { value: moment().startOf('day') },
            endDate: { value: moment().startOf('day').add(1, 'y') }
        };
        filters = Object.assign({}, filters, filtersFormUrlFactory());
        return filters;
    }

    componentDidMount() { this.search(); }

    search() {
        this.setState(
            { leaves: { arr: [], status: STATUS.LOADING } },
            () => {
                $.ajax({
                    type: 'GET',
                    contentType: 'application/json',
                    url: this.Endpoints().getLeaves({ filters: this.state.filters }),
                    dataType: 'json',
                    cache: false,
                    success: (data: any, textStatus: any, jqXHR: any) => {
                        if (data.data.length === 0) {
                            if (this.props.onEmpty) { this.props.onEmpty(); }
                            return;
                        }
                        let leaves = leavesFactory(data.data, (this.getCaches().getState() as any).users);
                        leaves = leaves.sort((a: any, b: any) => {
                            return (new Date(a.startDate)).getTime() - (new Date(b.startDate)).getTime();
                        });
                        this.setState({ leaves: { arr: leaves, status: STATUS.READY } });
                    },
                    error: (jqXHR: any, textStatus: any, errorThrown: any) => {
                        this.setState(
                            {
                                leaves: {
                                    arr: [],
                                    status: STATUS.ERROR
                                }
                            }
                        );
                        this.ajaxError(jqXHR, textStatus, errorThrown);
                    },
                });
            });
    }

    render() {
        if (this.state.leaves.arr.length === 0 || this.state.leaves.status === STATUS.LOADING) {
            return null as any;
        }
        return (
            <div className="card h-100">
                <div className="card-body">
                    <Table leaves={this.state.leaves} filters={this.state.filters} />
                </div>
            </div>
        );
    }

    getEmployee() {
        return (this.getSession().getState() as any).companyUser.id;
    }
}