import * as React from 'react';
import { Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import Component from '../../Component';
import Anchor from '../../Components/Anchor';
import Button from '../../Components/Button';
import Date from '../../Components/Date';

export default class SubscribeNow extends Component {
    constructor(props: any) {
        super(props);
        const moment = this.getMomentWithLocale();
        this.state = {
            billingInterval: 12,
            billingIntervalOver: null,
            currency: SubscribeNow.getCurrency(this.getSession().getState()),
            changePricePopoverOpen: false,
            startsOn: moment()
        };
    }

    public static getCurrency(session: any): any {
        try {
            const country = session.company.country;
            if (session.company.country === 'RO') {
                return 'RON';
            }
            const uecountries = ['AT', 'BE', 'BG', 'HR', 'CY', 'CZ', 'DK', 'EE', 'FI', 'FR', 'DE', 'GR',
                'HU', 'IE', 'IT', 'LV', 'LT', 'LU', 'MT', 'NL', 'PL', 'PT', 'SK', 'SI', 'ES', 'SE']
                .concat(['IS', 'LI', 'NO']);
            if (uecountries.indexOf(country) > -1) {
                return 'EUR';
            }
        } catch (error) {
            return 'USD';
        }
        return 'USD';
    }

    toggleChangePrice() { this.setState({ changePricePopoverOpen: !this.state.changePricePopoverOpen }); }

    render() {
        const t = this.translate();
        return (
            <div className="row">
                <div className="col-12">
                    <div className={this.getCardClassNameSize()}>
                        <div className="card-body">
                            <table className="table table-sm w-100">
                                <thead>
                                    <tr>
                                        <th className="border-0">
                                            {t('subscription')}
                                            <Anchor
                                                id={'ChangePricePopover' + this.cid}
                                                faIcon="fas fa-caret-down"
                                                className="float-right"
                                                onClick={() => { this.toggleChangePrice(); }}
                                            >
                                                {this.state.currency}
                                            </Anchor>
                                            <Popover
                                                placement="bottom"
                                                isOpen={this.state.changePricePopoverOpen}
                                                target={'ChangePricePopover' + this.cid}
                                                onClick={() => { this.toggleChangePrice(); }}
                                            >
                                                <PopoverHeader>
                                                    {t('change.currency')}
                                                </PopoverHeader>
                                                <PopoverBody>
                                                    <div className="text-center">
                                                        {
                                                            ['USD', 'EUR', 'RON'].map((currency: any) => {
                                                                return this.state.currency === currency ?
                                                                    <></>
                                                                    : <Anchor key={currency} className="ml-2" onClick={() => { this.setState({ currency: currency }); }}                                                                            >
                                                                        {currency}
                                                                    </Anchor>
                                                            })
                                                        }
                                                    </div>
                                                </PopoverBody>
                                            </Popover>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="p-0 border-0">
                                            <div className="d-flex w-100 text-center">
                                                {((((this.getSession().getState() as any)?.company?.country === 'RO'
                                                    && (this.getSession().getState() as any)?.company?.id > 22800))
                                                    || ((this.getSession().getState() as any)?.company?.id === 1123)) ?
                                                    <div className="w-100 h-100">
                                                        {this.getPriceAnchor(12)}
                                                    </div>
                                                    :
                                                    <>
                                                        <div className="w-50 h-100">
                                                            {this.getPriceAnchor(1)}
                                                        </div>
                                                        <div className="w-50 h-100">
                                                            {this.getPriceAnchor(12)}
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="border-0">
                                            {t('employees')}
                                            <span className="float-right font-weight-bold">
                                                {this.props.currentSubscription.currentUsers}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr className="d-none">
                                        <td>
                                            <div>
                                                <small className="font-weight-light">
                                                    {t('automatically.renews.on')}
                                                    {' '}
                                                    <Date date={this.state.startsOn.add(this.state.billingInterval)} />
                                                </small>
                                            </div>
                                            <div>
                                                <small className="font-weight-light">
                                                    {t('VAT.may.be.added.based.on.your.location')}
                                                </small>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="border-0">
                                            <Button
                                                type="button"
                                                className="mt-3 btn btn-outline-primary btn-block"
                                                faIcon="fa fa-shopping-cart"
                                                onClick={() => {
                                                    this.goTo(this.Urls().getOrderSubscription({
                                                        currency: this.state.currency,
                                                        interval: this.state.billingInterval
                                                    }));
                                                }}
                                            >
                                                {t('subscribe')}
                                            </Button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    getPrice(interval: any) {
        const price = (this.props.currentSubscription.currentUsers * (interval === 12 ? 10 : 1) * this.props.orderSettings.prices[this.state.currency]).toFixed(2);
        switch (this.state.currency) {
            case 'RON':
                return (<span>{price} RON</span>);
            case 'EUR':
                return (<span>&euro;{price}</span>);
            case 'USD':
            default:
                return (<span>${price}</span>);
        }
    }

    getPriceSave(interval: any) {
        if (interval !== 12) {
            return null;
        }
        const price = (this.props.currentSubscription.currentUsers * (interval === 12 ? 2 : 0) * this.props.orderSettings.prices[this.state.currency]).toFixed(2);
        let priceEl: any = null;
        switch (this.state.currency) {
            case 'RON':
                priceEl = (<span>{price} RON</span>);
                break;
            case 'EUR':
                priceEl = (<span>&euro;{price}</span>);
                break;
            case 'USD':
            default:
                priceEl = (<span>${price}</span>);
        }
        const t = this.translate();
        return (<sup className="ml-1 d-inline-block">{t((this.state.billingInterval !== 12 ? 'save' : 'saving'))} {priceEl}</sup>);
    }

    getBillingIntervalClassAndStyle(interval: any) {
        let className = 'p-2 d-block h-100';
        let style: any = {};
        className += (this.state.billingInterval === interval) ?
            ' text-white bg-primary font-weight-bold' : ' text-dark bg-light';
        style.opacity = (this.state.billingIntervalOver === interval) ? 1.0 : 0.5;
        return { className: className, style: style };
    }

    getPriceAnchor(interval: any) {
        const t = this.translate();
        return (
            <Anchor
                onClick={() => { this.setState({ billingInterval: interval }); }}
                onMouseOver={() => { this.setState({ billingIntervalOver: interval }); }}
                onMouseOut={() => { this.setState({ billingIntervalOver: null }); }}
                {...this.getBillingIntervalClassAndStyle(interval)}
            >
                {interval === 1 ? t('monthly') : t('yearly')}
                <span className="d-block">
                    {this.getPrice(interval)}
                    {this.getPriceSave(interval)}
                </span>
            </Anchor>
        );
    }
}