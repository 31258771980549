import * as React from 'react';
import Component from '../../../Component';
import Submit from '../../../Components/Submit';
import Error from '../../../Components/Error';
import SelectField from '../../../Components/SelectField';
import * as $ from 'jquery';
import { STATUS } from '../../../General';
import { countriesOptionsFactory } from '../../../SelectOptionsFactories';
import Table from './Table';

export default class Form extends Component {
    static supportedCountries: any = ['AE', 'AM', 'AR', 'AT', 'AU', 'BE', 'BH', 'BG', 'BR', 'BS', 'BY', 'CA', 'CH', 'CN', 'CZ', 'CY', 'CO', 'CG', 'DE', 'DK', 'DZ', 'EE', 'EG', 'ES', 'FI', 'FR',
        'GB', 'CR',
        //'GB-ENG', 'GB-NIR', 'GB-SCT', 'GB-WLS', 
        'GI', 'GR', 'GE', 'KW', 'PA',
        'HR', 'HU', 'HK', 'ID', 'IE', 'IL', 'IN', 'IS', 'IT', 'JO', 'JP', 'JM', 'LB', 'LT', 'LU', 'LV', 'MA', 'ME', 'MK', 'MD', 'MN', 'MM', 'MY', 'MT',
        'MX', 'MY', 'NZ', 'NL', 'NO', 'NG' ,'PL', 'PH', 'PT', 'RO', 'RU', 'RS', 'RW', 'TH', 'TW', 'QA', 'SE', 'SG', 'SI', 'SK', 'SR', 'TR', 'OM',
        'VN', 'UA', 'UG', 'US', 'ZA', 'AO', 'CL', 'CN', 'CO', 'JP', 'PH', 'PE', 'TH', 'PK', 'KE', 'ET', 'TZ', 'TN', 'TT' ,'SA', 'MU', 'KH', 'GH', 'ZM'
    ];
    static countriesWithStates: any = {
        'AU': [
            { value: 'AU-ACT', text: 'Australian Capital Territory' },
            { value: 'AU-NSW', text: 'New South Wales' },
            { value: 'AU-NT', text: 'Northern Territory' },
            { value: 'AU-QLD', text: 'Queensland' },
            { value: 'AU-SA', text: 'South Australia' },
            { value: 'AU-TAS', text: 'Tasmania' },
            { value: 'AU-VIC', text: 'Victoria' },
            { value: 'AU-WA', text: 'Western Australia' },
        ],
        'CA': [
            { value: 'CA-AB', text: 'Alberta' },
            { value: 'CA-BC', text: 'British Columbia' },
            { value: 'CA-MB', text: 'Manitoba' },
            { value: 'CA-NB', text: 'New Brunswick' },
            { value: 'CA-NL', text: 'Newfoundland and Labrador' },
            { value: 'CA-NS', text: 'Nova Scotia' },
            { value: 'CA-ON', text: 'Ontario' },
            { value: 'CA-PE', text: 'Prince Edward Island' },
            { value: 'CA-QC', text: 'Quebec' },
            { value: 'CA-SK', text: 'Saskatchewan' },
            { value: 'CA-NT', text: 'Northwest Territories' },
            { value: 'CA-NU', text: 'Nunavut' },
            { value: 'CA-YT', text: 'Yukon' },
        ],
        'CH': [
            { value: 'CH-AG', text: 'Aargau' },
            { value: 'CH-AI', text: 'Appenzell Innerrhoden' },
            { value: 'CH-AR', text: 'Appenzell Ausserrhoden' },
            { value: 'CH-BE', text: 'Bern' },
            { value: 'CH-BL', text: 'Basel-Landschaft' },
            { value: 'CH-BS', text: 'Basel-Stadt' },
            { value: 'CH-FR', text: 'Fribourg' },
            { value: 'CH-GE', text: 'Geneva' },
            { value: 'CH-GL', text: 'Glarus' },
            { value: 'CH-GR', text: 'Grisons' },
            { value: 'CH-JU', text: 'Jura' },
            { value: 'CH-LU', text: 'Luzern' },
            { value: 'CH-NE', text: 'Neuchâtel' },
            { value: 'CH-NW', text: 'Nidwalden' },
            { value: 'CH-OW', text: 'Obwalden' },
            { value: 'CH-SG', text: 'St. Gallen' },
            { value: 'CH-SH', text: 'Schaffhausen' },
            { value: 'CH-SO', text: 'Solothurn' },
            { value: 'CH-SZ', text: 'Schwyz' },
            { value: 'CH-TG', text: 'Thurgau' },
            { value: 'CH-TI', text: 'Ticino' },
            { value: 'CH-UR', text: 'Uri' },
            { value: 'CH-VD', text: 'Vaud' },
            { value: 'CH-VS', text: 'Valais' },
            { value: 'CH-ZG', text: 'Zug' },
            { value: 'CH-ZH', text: 'Zürich' },
        ],
        'DE': [
            { value: 'DE-BW', text: 'Baden-Württemberg' },
            { value: 'DE-BY', text: 'Bavaria' },
            { value: 'DE-BE', text: 'Berlin' },
            { value: 'DE-BB', text: 'Brandenburg' },
            { value: 'DE-HB', text: 'Bremen' },
            { value: 'DE-HH', text: 'Hamburg' },
            { value: 'DE-HE', text: 'Hesse' },
            { value: 'DE-MV', text: 'Mecklenburg-Vorpommern' },
            { value: 'DE-NI', text: 'Lower Saxony' },
            { value: 'DE-NW', text: 'North Rhine-Westphalia' },
            { value: 'DE-RP', text: 'Rhineland-Palatinate' },
            { value: 'DE-SL', text: 'Saarland' },
            { value: 'DE-SN', text: 'Saxony' },
            { value: 'DE-ST', text: 'Saxony-Anhalt' },
            { value: 'DE-SH', text: 'Schleswig-Holstein' },
            { value: 'DE-TH', text: 'Thuringia' }
        ],
        'ES': [
            { value: 'ES-CT', text: 'Catalunya' },
            { value: 'ES-MD', text: 'Community of Madrid' },
        ],
        'GB': [
            { value: 'GB-ENG', text: 'England' },
            { value: 'GB-NIR', text: 'Northern Ireland' },
            { value: 'GB-SCT', text: 'Scotland' },
            { value: 'GB-WLS', text: 'Wales' },
        ],
        'NZ': [
            { value: 'NZ-AUK', text: 'Auckland' },
            { value: 'NZ-BOP', text: 'Bay of Plenty' },
            { value: 'NZ-CAN', text: 'Canterbury' },
            { value: 'NZ-CIT', text: 'Chatham Islands' },
            { value: 'NZ-GIS', text: 'Gisborne' },
            { value: 'NZ-HKB', text: 'Hawke\'s Bay' },
            { value: 'NZ-MBH', text: 'Marlborough' },
            { value: 'NZ-MVT', text: 'Manawatu-Wanganui' },
            { value: 'NZ-NSN', text: 'Nelson' },
            { value: 'NZ-NTL', text: 'Northland' },
            { value: 'NZ-OTA', text: 'Otago' },
            { value: 'NZ-SCN', text: 'South Canterbury' },
            { value: 'NZ-STL', text: 'Southland' },
            { value: 'NZ-TAS', text: 'Tasman' },
            { value: 'NZ-TKI', text: 'Taranaki' },
            { value: 'NZ-WGN', text: 'Wellington' },
            { value: 'NZ-WTC', text: 'West Coast' },
        ],
        'US': [
            { value: 'US-AL', text: 'Alabama' },
            { value: 'US-AK', text: 'Alaska' },
            { value: 'US-AZ', text: 'Arizona' },
            { value: 'US-AR', text: 'Arkansas' },
            { value: 'US-CA', text: 'California' },
            { value: 'US-CO', text: 'Colorado' },
            { value: 'US-CT', text: 'Connecticut' },
            { value: 'US-DE', text: 'Delaware' },
            { value: 'US-FL', text: 'Florida' },
            { value: 'US-GA', text: 'Georgia' },
            { value: 'US-HI', text: 'Hawaii' },
            { value: 'US-ID', text: 'Idaho' },
            { value: 'US-IL', text: 'Illinois' },
            { value: 'US-IN', text: 'Indiana' },
            { value: 'US-IA', text: 'Iowa' },
            { value: 'US-KS', text: 'Kansas' },
            { value: 'US-KY', text: 'Kentucky' },
            { value: 'US-LA', text: 'Louisiana' },
            { value: 'US-ME', text: 'Maine' },
            { value: 'US-MD', text: 'Maryland' },
            { value: 'US-MA', text: 'Massachusetts' },
            { value: 'US-MI', text: 'Michigan' },
            { value: 'US-MN', text: 'Minnesota' },
            { value: 'US-MS', text: 'Mississippi' },
            { value: 'US-MO', text: 'Missouri' },
            { value: 'US-MT', text: 'Montana' },
            { value: 'US-NE', text: 'Nebraska' },
            { value: 'US-NV', text: 'Nevada' },
            { value: 'US-NH', text: 'New Hampshire' },
            { value: 'US-NJ', text: 'New Jersey' },
            { value: 'US-NM', text: 'New Mexico' },
            { value: 'US-NY', text: 'New York' },
            { value: 'US-NC', text: 'North Carolina' },
            { value: 'US-ND', text: 'North Dakota' },
            { value: 'US-OH', text: 'Ohio' },
            { value: 'US-OK', text: 'Oklahoma' },
            { value: 'US-OR', text: 'Oregon' },
            { value: 'US-PA', text: 'Pennsylvania' },
            { value: 'US-RI', text: 'Rhode Island' },
            { value: 'US-SC', text: 'South Carolina' },
            { value: 'US-SD', text: 'South Dakota' },
            { value: 'US-TN', text: 'Tennessee' },
            { value: 'US-TX', text: 'Texas' },
            { value: 'US-UT', text: 'Utah' },
            { value: 'US-VT', text: 'Vermont' },
            { value: 'US-VA', text: 'Virginia' },
            { value: 'US-WA', text: 'Washington' },
            { value: 'US-WV', text: 'West Virginia' },
            { value: 'US-WI', text: 'Wisconsin' },
            { value: 'US-WY', text: 'Wyoming' },
            { value: 'US-DC', text: 'District Of Columbia' },
        ]
    };

    static years = [2023, 2024,2025];

    constructor(props: any) {
        super(props);
        const moment = this.getMomentWithLocale();
        this.state = {
            xhr: undefined,
            hoes: {
                arr: [],
                status: STATUS.NONE
            },
            country: {
                value: this.getCountry(),
                rules: this.Rules().BasicSelectRules(),
            },
            state: {
                value: [],
            },
            year: {
                value: [moment().year()],
            },
            name: {
                touched: false,
                rules: this.Rules().BasicInputRules(),
            },
            date: {
                value: moment(),
                touched: false,
            },
            import: {
                pleaseWait: false
            },
        };
    }

    getCountry() {
        if (Form.supportedCountries.filter((country: any) => {
            return country === this.props.office.country;
        }).length > 0) {
            return [this.props.office.country];
        }
        return [];
    }

    componentDidMount() {
        if (this.state.country.value && this.state.country.value.length > 0) {
            this.search();
        }
    }

    render() {
        const t = this.translate();
        const caches: any = this.getCaches().getState();
        const countriesOptions = countriesOptionsFactory(caches.countries.arr[this.language()]
            .filter((country: any) => {
                const supportedCountries = Form.supportedCountries;
                for (let scountry of supportedCountries) {
                    if (scountry === country.id) {
                        return true;
                    }
                }
                return false;
            }));
        const statesOptions = this.statesOptionsFactory(this.state.country.value[0]);
        const yearsOptions = Form.years.map((year) => {
            return {
                value: year,
                text: year
            };
        });
        return <form onSubmit={(event: any) => { this.submit(event); }}>
            <div className="row">
                <div className="col-12 mb-3">
                    <SelectField
                        defaultValue={this.state.country.value}
                        touched={this.state.country.touched}
                        options={countriesOptions}
                        multiple={false}
                        rules={this.state.country.rules}
                        placeholder={t('country')}
                        onChange={(value: any) => {
                            this.setState({ country: Object.assign({}, this.state.country, { value: value }) },
                                () => {
                                    let states = this.statesOptionsFactory(this.state.country.value[0]);
                                    let state: any = [];
                                    if (states) { state = [states[0].value]; }
                                    this.setState(
                                        { state: Object.assign({}, this.state.state, { value: state }) },
                                        () => { this.search(); });
                                }
                            );
                        }}
                    />
                </div>
                {(this.countryHasStates(this.state.country.value)) ?
                    <div className="col-12 mb-3">
                        <SelectField
                            defaultValue={this.state.state.value}
                            value={this.state.state.value}
                            touched={this.state.state.touched}
                            options={statesOptions}
                            multiple={false}
                            rules={this.state.state.rules}
                            placeholder={t('state')}
                            onChange={(value: any) => {
                                this.setState(
                                    { state: Object.assign({}, this.state.state, { value: value }) },
                                    () => { this.search(); }
                                );
                            }}
                        />
                    </div>
                    : null}
                <div className="col-12 mb-3">
                    <SelectField
                        defaultValue={this.state.year.value}
                        touched={this.state.year.touched}
                        options={yearsOptions}
                        multiple={false}
                        rules={this.state.year.rules}
                        placeholder={t('year')}
                        onChange={(value: any) => {
                            this.setState(
                                { year: Object.assign({}, this.state.year, { value: value }) },
                                () => { this.search(); }
                            );
                        }}
                    />
                </div>
            </div>
            {this.hoesTable()}
        </form>
    }

    search() {
        if (this.state.xhr) {
            this.state.xhr.abort();
            this.setState({ xhr: undefined, hoes: { arr: [], status: STATUS.NONE } }, () => {
                this.searchNow();
            });
        } else {
            this.searchNow();
        }
    }

    searchNow() {
        const xhr = $.ajax({
            type: 'GET',
            contentType: 'application/json',
            url: this.Endpoints().getHoeImportSearch(
                this.state.state.value && this.state.state.value[0] ?
                    this.state.state.value[0] : this.state.country.value[0],
                this.state.year.value),
            dataType: 'json',
            cache: false,
            success: (data: any, textStatus: any, jqXHR: any) => {
                const hoes = data.data.map((hoe: any) => {
                    hoe.import = true;
                    return hoe;
                });
                this.setState({ xhr: undefined, hoes: { arr: hoes, status: STATUS.READY } });
            },
            error: (jqXHR, textStatus, errorThrown) => {
                this.ajaxError(jqXHR, textStatus, errorThrown);
            },
        });
        this.setState({ xhr: xhr, hoes: { arr: [], status: STATUS.LOADING } });
    }

    isFormValid() {
        return this.Validator().validate(this.state.country) &&
            this.Validator().validate(this.state.year);
    }

    submit(event: any) {
        event.preventDefault();
        this.setState({
            country: Object.assign({}, this.state.country, { touched: true }),
            year: Object.assign({}, this.state.year, { touched: true })
        }, () => {
            if (this.isFormValid()) { this.addHoes(); }
        });
    }

    addHoes() {
        const t = this.translate();
        const hoes = this.state.hoes.arr.filter((hoe: any) => {
            const toImport = hoe.import;
            delete hoe.import;
            return toImport;
        }).map((hoe: any) => {
            hoe.calendar = {};
            hoe.calendar.id = this.props.office.id;
            return hoe;
        });
        $.ajax({
            type: 'PUT',
            contentType: 'application/json',
            url: this.Endpoints().createHoes(),
            dataType: 'json',
            cache: false,
            data: JSON.stringify(hoes),
            beforeSend: () => {
                this.setState({ import: { pleaseWait: true } });
            },
            success: (data: any, textStatus: any, jqXHR: any) => {
                this.successToastr(t('the.public.holidays.and.events.were.added'));
                if (this.props.onClose) {
                    this.props.onClose();
                }
            },
            error: (jqXHR, textStatus, errorThrown) => {
                this.ajaxError(jqXHR, textStatus, errorThrown);
            },
            complete: (jqXHR, textStatus) => {
                this.setState({ import: { pleaseWait: false } });
            }
        });
    }

    hasHoesTable() {
        return this.state.hoes.status === STATUS.READY || this.state.hoes.status === STATUS.LOADING ? true : false;
    }

    hoesTable() {
        switch (this.state.hoes.status) {
            case STATUS.NONE:
                return undefined;
            case STATUS.LOADING:
                return <div className="row">
                    <div className="col-12 mb-3">
                        {this.renderLoading()}
                    </div>
                </div>
            case STATUS.READY:
                const t = this.translate();
                return <><div className="row" key="Modal-ImportHoes-Form-Table">
                    <div className="col-12 mb-3">
                        <Table
                            hoes={this.state.hoes.arr}
                        />
                    </div>
                </div>
                    <div className="row" key="Modal-ImportHoes-Form-Import">
                        <div className="col-12 mb-3">
                            <Submit
                                faIcon="far fa-hdd"
                                disabled={!this.isFormValid()}
                                pleaseWait={this.state.import.pleaseWait}
                            >
                                {t('import')}
                            </Submit>
                        </div>
                    </div></>
            case STATUS.ERROR:
                return <div className="row">
                    <div className="col-12 mb-3">
                        <Error />
                    </div>
                </div>
            default:
                return undefined;
        }
    }

    countryHasStates(country: any) {
        return Form.countriesWithStates[country] ? true : false;
    }

    statesOptionsFactory(country: any) {
        return Form.countriesWithStates[country];
    }
}